import { breakpoints as size } from "styles/grid";

const colors = {
	white: "hsl(0, 0%, 95%)",
	whiteAlways: "hsl(0, 0%, 95%)",
	whiteTrue: "#fff",
	whiteTrueAlways: "#fff",
	black: "#373737",
	blackAlways: "#373737",
	grey: "#8a8a8a",
	greyAlways: "#8a8a8a",
	primary: "#315398",
	defaultBluePrimaryAlways: "#315398",
	blue: "#5085eb",
	red: "#ff0202",
	redOnHover: "#ff2a2a",
	landingErrorMessage: "#ff2a2a",
	secondary: "#cdff2e",
	success: "#07bc0c",
	warning: "#FF8800",
	transparent: "transparent",
	textMuted: "#8a8a8a",
	buttonsDefaultGrey: "#f4f4f4",
	menuItemHover: "#f4f4f4",
	menuItemHoverAlwaysLight: "#f4f4f4",
	menuItemActive: "#eeeeee",
	menuItemActiveAlwaysLight: "#eeeeee",
	inputBorder: "#d0d0d0",
	inputBorderHover: "#bebebe",
	checkboxTextColorDisabled: "#bebebe",
};

const colorsDarkTheme = {
	white: "#202020",
	whiteAlways: "hsl(0, 0%, 95%)",
	whiteTrue: "#202020",
	whiteTrueAlways: "#fff",
	black: "hsl(0, 0%, 95%)",
	blackAlways: "#373737",
	grey: "#a9a9a9",
	greyAlways: "#8a8a8a",
	primary: "hsl(0, 0%, 95%)",
	defaultBluePrimaryAlways: "#315398",
	primaryLight: "#568dfd",
	blue: "#5085eb",
	red: "#ff0202",
	redOnHover: "#ff2a2a",
	landingErrorMessage: "#ff2a2a",
	secondary: "#cdff2e",
	success: "#07bc0c",
	warning: "#FF8800",
	transparent: "transparent",
	textMuted: "#A9A9A9",
	buttonsDefaultGrey: "#373737",
	menuItemHover: "#373737",
	menuItemHoverAlwaysLight: "#f4f4f4",
	menuItemActive: "#424242",
	menuItemActiveAlwaysLight: "#eeeeee",
	inputBorder: "#878787",
	inputBorderHover: "#646464",
	checkboxTextColorDisabled: "#878787",
};

const headingTypes = {
	h1: {
		fontSize: 1.25,
		marginBottom: 0.75,
	},
	h2: {
		fontSize: 1.25,
		marginBottom: 0.75,
	},
	h3: {
		fontSize: 1.1,
		marginBottom: 0.6,
	},
	h4: {
		fontSize: 0.85,
		marginBottom: 0.5,
	},
	h5: {
		fontSize: 0.75,
		marginBottom: 0.35,
	},
	h6: {
		fontSize: 0.5,
		marginBottom: 0.25,
	},
};

const buttonSizes = {
	xsmall: {
		fontSize: 0.75,
		lineHeigth: 0.9,
		padding: [0.15, 0.35],
	},
	small: {
		fontSize: 0.85,
		lineHeigth: 1,
		padding: [0.25, 0.75],
	},
	medium: {
		fontSize: 1,
		lineHeigth: 1.5,
		padding: [0.375, 1],
	},
	large: {
		fontSize: 1.2,
		lineHeigth: 1.7,
		padding: [0.45, 1.25],
	},
	/**
	 * Przykład przycisku tego rozmiaru:
	 * Przyciski  'Zarejestruj' / 'Zaloguj' na Landingu
	 */
	xLarge: {
		fontSize: 1.2,
		lineHeigth: 2,
		padding: [0.8, 1.25],
	},
	/**
	 * More info buttons
	 */
	special: {
		fontSize: 1,
		lineHeigth: 1.15,
		padding: [0.15, 0.5, 0.15],
	},
};

const boxShadowsDarkMode = {
	minimal: [
		"0px 0px 0px 1px rgba(235, 235, 235, 0.4)",
		`-1px 0 2px 0 rgba(235, 235, 235, 0.4)`,
		`1px 0 2px 0 rgba(235, 235, 235, 0.1)`,
		`0 1px 1px 0 rgba(235, 235, 235, 0.22)`,
	],
	buttonWithText: [
		"0 1px 2px 0 rgb(0 0 0 / 0.3)",
		"0 1px 2px -1px rgb(0 0 0 / 0.3)",
	],
	panelTop: [
		`-2px -2px 2px 0 rgba(0, 0, 0, 0.08)`,
		`2px -2px 2px 0 rgba(0, 0, 0, 0.08)`,
	],
	panel: [
		`0px 2px 2px 0 rgba(0, 0, 0, 0.08)`,
		`0px 2px 2px rgba(0,0,0,0.16)`,
	],
	cardLight: [
		"0 1px 2px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
	],
	cardDark: ["0 1px 2px 0 rgb(0 0 0 / 1),0 1px 2px -1px rgb(0 0 0 / 1)"],
};

const boxShadows = {
	minimal: [
		"0px 0px 0px 1px rgba(0,0,0,0.08)",
		`-1px 0 2px 0 rgba(0, 0, 0, 0.08)`,
		`1px 0 2px 0 rgba(0, 0, 0, 0.1)`,
		`0 1px 1px 0 rgba(0, 0, 0, 0.22)`,
	],
	buttonWithText: [
		"0 1px 2px 0 rgb(0 0 0 / 0.3)",
		"0 1px 2px -1px rgb(0 0 0 / 0.3)",
	],
	panelTop: [
		`-2px -2px 2px 0 rgba(0, 0, 0, 0.08)`,
		`2px -2px 2px 0 rgba(0, 0, 0, 0.08)`,
	],
	panel: [
		`0px 2px 2px 0 rgba(0, 0, 0, 0.08)`,
		`0px 2px 2px rgba(0,0,0,0.16)`,
	],
	cardLight: [
		"0 1px 2px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
	],
	cardDark: ["0 1px 2px 0 rgb(0 0 0 / 1),0 1px 2px -1px rgb(0 0 0 / 1)"],
};

const borderRadiuses = {
	minimal: 0.175,
	panel: 1.25,
	element: 1.25,
	card: 1.25,
	table: 0.75,
	messages: 0.75,
	progressBarLanding: 0.75,
	progressBar: 0.65,
	landing: 1.5,
};

const topHeaderHeight = {
	desktop: 61,
	tablet: 57,
	mobile: 52,
};

const bottomNavMenuHeight = "63px";
const topNavMenuHeight = 42;

export {
	colors,
	colorsDarkTheme,
	headingTypes,
	buttonSizes,
	boxShadows,
	boxShadowsDarkMode,
	borderRadiuses,
	size,
	topHeaderHeight,
	bottomNavMenuHeight,
	topNavMenuHeight,
};
